import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { connect } from "react-redux";
import ChangePassword from "./views/Pages/Login/ChangePassword";
const today = new Date();

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
// For Automatic logout on Monday First time
if((today.getDay() === 1) && (localStorage.getItem('logOutAlready') !== 'true')) {
  localStorage.clear()
  window.location.reload();
  localStorage.setItem("logOutAlready",true)
} else if(today.getDay() !== 1){
  localStorage.setItem("logOutAlready",false)
}
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/changepassword"
              name="ChangePassword Page"
              render={(props) => <ChangePassword {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (props) => {
  //console.log(props)
  return {
    props: props,
  };
};
export default connect(mapStateToProps)(App);
