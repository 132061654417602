export var url = "https://api.technotery.in";
// export var url = "https://drivenscan.com";
//var url = "";
//var url = "https://app-api.technotery.in"

var offlineurl = "/offlineApi";

//var partnerId = "001-sandip";
export const configuration1 = {
  resultTxt: offlineurl + "/getVinRecords.json",
  getQRList: offlineurl + "/getQrList.json",
  imagePath: offlineurl + "/scanImages/",
  getOrders: offlineurl + "/api_v2/getOrders.php",
  GetPartDetails: offlineurl + "/api_v2/GetPartDetails.php",
  login: offlineurl + "/api_v2/loginCheck.php",
  getImages: offlineurl + "/api_v2/getImages.php",
  UpdateDentDetails: offlineurl + "/api_v2/UpdateDentDetails.php",
  Update_ResultTxt: offlineurl + "/api_v2/Update_ResultTxt.php",
  PostCustomerInfo: offlineurl + "/api_v2/PostCustomerInfo.php",
  getCustomerInfo: offlineurl + "/api_v2/getCustomerInfo.php",
  reportImage: offlineurl + "/scanImages/",
  uploadExtraImages: offlineurl + "/api_v2/uploadExtraImages.php",
  getExtraImages: offlineurl + "/api_v2/getExtraImages.php",
  deleteCommon: offlineurl + "/api_v2/delete-common.php",
  getDitInfo: offlineurl + "/api_v2/getDitInfo.php",
  getResultCH: offlineurl + "/api_v2/getResultCH.php",
  getResultSL: offlineurl + "/api_v2/getResultSL.php",
  getResultDat: offlineurl + "/api_v2/getResultDat.php",
  Update_ResultSL: offlineurl + "/api_v2/Update_ResultSL.php",
  GetResultSLChecked: offlineurl + "/api_v2/GetResultSLChecked.php",
  PostExtraImageDents: offlineurl + "/api_v2/PostExtraImageDents.php",
  getExtraImageDents: offlineurl + "/api_v2/getExtraImageDents.php",
  PostEditorChanges: offlineurl + "/api_v2/PostEditorChanges.php",
  getClients: offlineurl + "/api_v2/getClients.php",
  PostClients: offlineurl + "/api_v2/PostClients.php",
  getInsurenceClients: offlineurl + "/api_v2/JSON_API/getInsurenceClients.php",
  getData: offlineurl + "/api_v2/JSON_API/getData.php",
};

export const configuration = {
  apiBaseUrl: "http://localhost:9001/api/v1/",
  setLightStatus: url + '/api_v2/postAppInterfaceLightsStatus.php',
  postAppInterfaceColorStatus: url + '/api_v2/postAppInterfaceColor.php',
  resultTxt: url + "/api_v2/getVinRecords.php",
  validateAppInterface: url + '/api_v2/checkAppInterfaceConsoleAccess.php',
  getQRList: url + "/api_v2/getQrList.php",
  postSingleVINRecord: url + "/api_v2/scanlist/PostSingleVINRecord.php",
  getScanList: url + "/api_v2/scanlist/getScanList.php",
  getBase64: url + "/api_v2/imageToBase64.php",
  updateBase64: url + "/api_v2/replacePartImage.php",
  newDashboardImage: url + "/api_v2/newDashboardImages.php",
  getAppInterfaceStatus: url + "/api_v2/getAppInterfaceStatuses.php",
  resetInterfaceStatus: url + "/api_v2/resetAppInterfaceStatuses.php",
  postAppInterfaceStatus: url + "/api_v2/postAppInterfaceStatuses.php",
  imagePath: url,
  getOrders: url + "/api_v2/getOrders.php",
  getSearchedVinRecords: url + "/api_v2/searchVinRecords.php",
  GetPartDetails: url + "/api_v2/GetPartDetails.php",
  login: url + "/api_v2/loginCheck.php",
  getImages: url + "/api_v2/getImages.php",
  UpdateDentDetails: url + "/api_v2/UpdateDentDetails.php",
  Update_ResultTxt: url + "/api_v2/Update_ResultTxt.php",
  PostCustomerInfo: url + "/api_v2/PostCustomerInfo.php",
  getCustomerInfo: url + "/api_v2/getCustomerInfo.php",
  reportImage: url + "/scanImages/",
  uploadExtraImages: url + "/api_v2/uploadExtraImages.php",
  getExtraImages: url + "/api_v2/getExtraImages.php",
  deleteCommon: url + "/api_v2/delete-common.php",
  getDitInfo: url + "/api_v2/getDitInfo.php",
  getResultCH: url + "/api_v2/getResultCH.php",
  getResultSL: url + "/api_v2/getResultSL.php",
  getResultDat: url + "/api_v2/getResultDat.php",
  Update_ResultSL: url + "/api_v2/Update_ResultSL.php",

  Rotate_image : url + "/api_v2/rotateUploadImage.php",
  
  GetResultSLChecked: url + "/api_v2/GetResultSLChecked.php",
  PostExtraImageDents: url + "/api_v2/PostExtraImageDents.php",
  getExtraImageDents: url + "/api_v2/getExtraImageDents.php",
  PostEditorChanges: url + "/api_v2/PostEditorChanges.php",
  getClients: url + "/api_v2/getClients.php",
  PostClients: url + "/api_v2/PostClients.php",
  getInsurenceClients: url + "/api_v2/JSON_API/getInsurenceClients.php",
  getData: url + "/api_v2/JSON_API/getData.php",

  postFallbackDetails: url + "/api_v2/PostFallbackDetails.php",
  getFallbackDetails: url + "/api_v2/GetFallbackDetails.php",

  update360ImageLink: url + "/api_v2/update360ImageLink.php",
  get360ImageLink: url + "/api_v2/get360ImageLink.php",

  getFallbackLogoPath: url + "/scanImages/",

  uploadExtraScanListRecords:
    url + "/api_v2/scanlist/uploadExtraScanListRecordImages.php",

  getDashboardStatistics: url + "/api_v2/getDashboardStatistics.php",
  generatePDF: url + "/api_v2/reports/generatePDF.php",
  renameVIN: url + "/api_v2/PostRenameVIN.php",
  createEmptyVin: url + "/api_v2/CreateEmptyVin.php",
  createStickyNote : url + "/api_v2/notes/createStickyNote.php",
  getSingleStickyNote : url + "/api_v2/notes/getSingleStickyNote.php",
  getAllStickyNotes : url + "/api_v2/notes/getAllStickyNotes.php",
  updateStickyNote : url + "/api_v2/notes/updateStickyNote.php",
  deleteStickyNote : url + "/api_v2/notes/deleteStickyNote.php",
  // https://api.technotery.in/api_v2/reports/generatePDF.php
  getVinRecordsPaginate : url + "/api_v2/getVinRecordsPaginate.php",
  getQRListPaginate : url + "/api_v2/getQrListPaginate.php",
  searchGetVinRecords : url + "/api_v2/searchGetVinRecords.php",
  SearchQRDetails : url + "/api_v2/SearchQRDetails.php",
  updateQrList : url +"/api_v2/updateQrList.php",
  getSingleQRDetails : url + "/api_v2/getSingleQRDetails.php",
  getAllLocation : url + "/api_v2/getAllLocation.php",
  updateQRInfo : url + "/api_v2/updateQrInfo.php",
  restoreJsonBackup: url + "/api_v2/restoreJsonBackup.php",
  vinExistsOrNot: url + "/api_v2/vinExistsOrNot.php",
  generateSwissDATReport: url + "/api_v2/reports/generateSwissDATReport.php",
  datReportCheck : url + "/api_v2/datReportCheck.php",
  getLogMessage: url + "/api_v2/getLogMessage.php",
  uploadExtraImagesNew: url + "/api_v2/uploadExtraImagesNew.php",
  extraImageToBase64: url + "/api_v2/extraImageToBase64.php",
  downloadExtraImage: url + "/api_v2/downloadExtraImage.php",
  uploadExtraPartImage : url + "/api_v2/uploadExtraPartImage.php",
  deleteExtraPartImage : url + "/api_v2/deleteExtraPartImage.php",
  getExtraPartImage : url + "/api_v2/getExtraPartImage.php",
  editExtraPartImage : url + "/api_v2/editExtraPartImage.php",
  rotateExtraPartImage : url + "/api_v2/rotateExtraPartImage.php",
  extraPartImageToBase64: url + "/api_v2/extraPartImageToBase64.php",
  authorizernamestore: url + "/api_v2/storeAuthorizedName.php",
  getContractor: url + "/api_v2/getContractor.php",
  allExtraImagePDF: url + "/api_v2/reports/allExtraImagePDF.php",
  allPartImagePDF: url + "/api_v2/reports/allPartImagePDF.php",
  downloadReports: url + "/api_v2/reports/downloadReports.php",
  generateFullReport: url + "/api_v2/reports/generateFullReport.php",
  getInsuranceEmails: url + "/api_v2/getInsuranceEmails.php",
  createDuplicateVin: url + "/api_v2/createDuplicateVin.php",
  getDuplicateVins: url + "/api_v2/getDuplicateVins.php",
  generatePDFAllVinQr: url + "/api_v2/reports/generatePDFAllVinQr.php",
  excelRead: url + "/api_v2/excelRead.php",
  base64ToReport: url + "/api_v2/reports/base64ToReport.php",
  predamageFlag: url + "/api_v2/predamageFlag.php",
  automated_scripts: url + "/api_v2/automated-scripts/generatePDF.php",
  automated_scripts_PDFDAT: url + "/api_v2/automated-scripts/generatePDFDAT.php",
  getScanImages: url + "/api_v2/getScanImages.php",
  getGraphStatistics: url + "/api_v2/getGraphStatistics.php",
  createUser: url + "/api_v2/User/createUser.php",
  getTemplates: url + "/api_v2/User/getTemplates.php",
  getMenuUser: url + "/api_v2/User/getMenuUser.php",
  getUser: url + "/api_v2/User/getUser.php",
  activeDeactiveUser: url + "/api_v2/User/activeDeactiveUser.php",
  updateUser: url + "/api_v2/User/updateUser.php ",
  changePassword: url + "/api_v2/User/changePassword.php ",

  // Audatex URL
  taskService: url + "/api_v2/taskService.php",
  calculationService: url + "/api_v2/calculationService.php",

  // VI Related API's
  generateVIToken: url + "/api_v2/visualIntelligenceImage.php",
  visualIntelligenceImage: url + "/api_v2/visualIntelligenceImagePart2.php",
  savePDF: url + "/api_v2/VIPdf.php",
};
